import Masonry from 'masonry-layout';
import imagesloaded from 'imagesloaded';

const ImageGrid = function(images, selector) {

    const columns = 3;
    const gutter = 20;
    
    this.images = images;
    this.selector = selector;
    this.element = document.querySelector(selector);

    // Add HTML to page based on input image array
    const renderHtml = () => {
        const html = gridHtml(this.images);
        this.element.innerHTML = html;
    };

    // Instantiate the Bricks.js instance
    const setupBricks = () => {
        
        this.msnry = new Masonry( '.image-grid', {
            itemSelector: '.image',
            gutter: gutter,
            transitionDuration: 0
        });
        
        
    };

    // Add the loaded class to the parent so we can gracefully fade in the images
    const fadeInOnLoad = () => {
        setTimeout(() => {
            
            const loaded = imagesloaded(this.selector);
            loaded.on('progress', (instance, image) => {
                image.img.parentNode.parentNode.classList.add('loaded');
                this.msnry.layout();
            });

        }, 1000);
        
        
    };

    // Bind to this so that it can be used externally.
    this.init = () => {
        renderHtml();
        setupBricks();
        fadeInOnLoad();
    };

    // Generate html for a single image
    const imageHtml = ({filename, width, height}) => {        
        
        const heightPadding = height/width * 100;

        return `<div class="image">
            <div class="image-sizer" style="padding-top:${heightPadding}%">
                <img src="/images/digital/1024/${filename}" style="width: 100%;" alt="">
            </div>
            <div class="overlay"></div>
        </div>`;
    };


    // Generate the wrapper html and add the images 
    const gridHtml = (images) => {
        const imagesHtml = images.map(imageHtml).join('');
        return `<div class="image-grid">
            ${imagesHtml}
        </div>`;
    };




    return this;
};



export default ImageGrid;